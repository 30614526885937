

























































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import {
  webhook,
  gitlab,
  jira,
  zendao,
  feishu,
  weixin,
  dingding,
} from '@/utils/contanst'
@Component({
  name: 'Integration',
})
export default class Integration extends VueBase {
  activeName = 'all'
  allApps = [
    {
      name: 'Jira',
      type: 'jira',
      num: jira,
      icon: require('@/assets/Jira.png'),
      description: '应用于软件开发和其他类型的项目管理',
      isSetting: false,
      hiddenBox: false,
      tag: 'defect',
    },
    {
      name: '禅道',
      type: 'settingZentao',
      icon: require('@/assets/chandao.png'),
      num: zendao,
      description: '开源的项目管理软件，专为敏捷开发和软件项目管理设计',
      isSetting: false,
      hiddenBox: false,
      tag: 'defect',
    },
    {
      name: 'GitLab',
      type: 'settingGitlab',
      icon: require('@/assets/GitLab.png'),
      num: gitlab,
      description: '全面的DevOps平台，集成了项目规划、源码管理、CI/CD',
      isSetting: false,
      hiddenBox: false,
      tag: 'defect',
    },

    {
      name: 'Jenkins',
      type: 'settingJenkins',
      icon: require('@/assets/Jenkins.png'),
      description: '用于持续集成和持续部署（CI/CD）流程',
      isSetting: false,
      hiddenBox: false,
      tag: 'CICD',
    },
    {
      name: '飞书',
      type: 'settingFeishu',
      icon: require('@/assets/fs.png'),
      num: feishu,
      description: '企业级协作工具，集成了即时通讯、视频会议、日历、文档',
      isSetting: false,
      hiddenBox: false,
      tag: 'message',
    },
    {
      name: '钉钉',
      type: 'settingDingtalk',
      num: dingding,
      icon: require('@/assets/dd.png'),
      description: '企业通讯和协作平台',
      isSetting: false,
      hiddenBox: false,
      tag: 'message',
    },
    {
      name: '企业微信',
      type: 'settingWorkwx',
      num: weixin,
      icon: require('@/assets/wx.png'),
      description: '腾讯推出的企业通讯和办公协作应用，高效互动连接',
      isSetting: false,
      hiddenBox: false,
      tag: 'message',
    },
    {
      name: 'Webhook',
      type: 'settinghook',
      num: webhook,
      icon: require('@/assets/Webhook.png'),
      description: '实时数据传输，高效互动连接',
      isSetting: false,
      hiddenBox: false,
      tag: 'other',
    },
    // {
    //   name: 'IntelliJ IDEA',
    //   type: 'settingIDEA',
    //   icon: require('@/assets/IntelliJ.png'),
    //   description: '一款强大的集成开发环境工具',
    //   isSetting: false,
    //   hiddenBox: false,
    //   loading: false,
    //   tag: 'IDE',
    // },
    {
      name: 'kubernetes',
      type: 'settingK8S',
      icon: require('@/assets/kubernetes.png'),
      description: '自动化容器化应用程序的部署、扩展和管理',
      isSetting: false,
      hiddenBox: false,
      loading: false,
      tag: 'other',
    },
    {
      name: '云效',
      type: 'settingYunXiao',
      icon: require('@/assets/yunxiao.png'),
      description: '阿里云推出的一款企业级的持续交付和DevOps平台',
      isSetting: false,
      hiddenBox: false,
      loading: false,
      tag: 'other',
    },
    {
      name: '蓝鲸智云',
      type: 'settingBlueKing',
      icon: require('@/assets/lanjing.png'),
      description: '腾讯云开发的一套企业级DevOps和云管理平台',
      isSetting: false,
      hiddenBox: false,
      loading: false,
      tag: 'other',
    },
  ]

  private filterApp = []

  private async summary() {
    const res = await this.services.vuln.summary()
    if (res.status === 201) {
      res.data.forEach((item: any) => {
        this.allApps.some((app: any) => {
          if (app.num == item) {
            app.isSetting = true
            return true
          }
        })
      })
    }
  }

  private toPath(app: any) {
    if (app.loading) {
      return
    }
    this.$router.push({
      name: app.type,
      params: {
        id: app.id,
      },
    })
  }

  getApps(activeName: string) {
    let apps: any = []
    if (activeName === 'all') {
      apps = this.getAll()
    } else {
      apps = this.allApps.filter((item: any) => item.tag === activeName)
    }
    // for (let i = 0; i <= apps.length; i++) {
    //   apps.push({
    //     name: '',
    //     type: '',
    //     icon: '',
    //     num: '',
    //     isSetting: false,
    //     hiddenBox: true,
    //     tag: '',
    //   })
    // }
    return apps
  }
  getAll() {
    if (!this.btnRole.includes(17)) {
      this.allApps = this.allApps.filter((item: any) => item.tag !== 'IDE')
    }
    if (!this.btnRole.includes(18)) {
      this.allApps = this.allApps.filter((item: any) => item.tag !== 'CICD')
    }
    if (!this.btnRole.includes(19)) {
      this.allApps = this.allApps.filter((item: any) => item.tag !== 'defect')
    }
    if (!this.btnRole.includes(20)) {
      this.allApps = this.allApps.filter((item: any) => item.tag !== 'message')
    }
    if (!this.btnRole.includes(21)) {
      this.allApps = this.allApps.filter((item: any) => item.tag !== 'other')
    }
    return this.allApps
  }

  created() {
    this.summary()
    // this.getApps()
  }
}
